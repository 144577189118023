import React, { useState, useEffect, useCallback } from "react";
import classNames from "classnames";
import t from "@utils/t";
import useLang from "@hooks/useLang";
import useArtists from "@staticQueries/ArtistsQuery";
import useOpenStudiosArtists from "@staticQueries/OpenStudiosArtistsQuery";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import { ArtistGrid } from "@molecules";
import { Container, Toggle } from "@atoms";
import loadable from "@loadable/component";

// todo: refactor this sort & filtering
// todo: use last name field

const Search = loadable(() => import("../molecules/Search"));

const Directory = React.memo(({ collection, className: _className }) => {
  const lang = useLang();
  const { openStudiosArtists } = useOpenStudiosArtists();
  const { teachingArtists } = useArtists();
  const items = { teachingArtists, openStudiosArtists }[collection];
  const filters = useCategoriesData()[lang === "en" ? "en" : "es"][collection];
  const { mediums } = useCategoriesData()[lang === "en" ? "en" : "es"];

  const [sortedItems, setSortedItems] = useState(items);
  const [filteredItems, setFilteredItems] = useState(sortedItems);
  const [results, setResults] = useState(filteredItems);
  const [activeFilters, setActiveFilters] = useState([]);
  const [query, setQuery] = useState("");

  const [activeSort, setActiveSort] = useState(
    collection === "openStudiosArtists" ? "artist_no" : "alphabetical"
  );

  // alphabetize initial entries
  const alphabetize = useCallback(
    (arr, type, condition) => {
      if (condition) {
        const sorted = arr?.sort((a, z) => {
          if (activeSort === "artist_no") {
            return parseInt(a[type], 10) - parseInt(z[type], 10);
          }
          // sort Open Studios artists by last name, other items by first name
          return activeSort === "alphabetical" &&
            collection === "openStudiosArtists"
            ? a[type]?.split(" ").pop().localeCompare(z[type]?.split(" ").pop())
            : a[type]?.[0]?.localeCompare(z[type]);
        });
        setSortedItems(sorted);
      }
      setSortedItems(arr);
    },
    [items]
  );

  useEffect(() => {
    if (activeSort === "artist_no")
      alphabetize(items, "artistNumber", activeSort);
    if (activeSort === "alphabetical") alphabetize(items, "name", activeSort);
  }, [activeSort]);

  // then filter alphabetized data
  const updateFilters = useCallback(
    event => {
      const newFilter = event.target.id;

      setActiveFilters(currentFilters => {
        // if the list of current filters already includes the incoming filter,
        // remove it from the array of current filters
        if (currentFilters?.includes(newFilter)) {
          return currentFilters.filter(current => current !== newFilter);
        }
        // otherwise, add it to the array of current filters
        return [...currentFilters, newFilter];
      });
    },
    [filters, mediums]
  );

  // todo: optimize
  // apply filters to alphabetized data
  useEffect(() => {
    const mediumUids = mediums?.map(med => med.uid) || [];
    const tagUids = filters?.map(tag => tag.uid) || [];
    setFilteredItems(
      sortedItems?.filter(item => {
        // no filters
        if (activeFilters?.length === 0) return true;
        // if tags only
        if (!activeFilters.filter(value => mediumUids.includes(value)).length) {
          return activeFilters?.some(f =>
            item.tags?.map(tag => tag.uid)?.includes(f)
          );
        }
        // if mediums only
        if (!activeFilters.filter(value => tagUids.includes(value)).length) {
          return activeFilters?.some(f =>
            item.mediums?.map(med => med.uid)?.includes(f)
          );
        }
        // if both
        return (
          activeFilters?.some(f =>
            item.tags?.map(tag => tag.uid)?.includes(f)
          ) &&
          activeFilters?.some(f =>
            item.mediums?.map(med => med.uid)?.includes(f)
          )
        );
      })
    );
  }, [activeFilters, sortedItems]);

  // finally, change results every time filters are applied
  useEffect(() => {
    setResults(filteredItems);
  }, [filteredItems]);

  return (
    <section className={classNames("py-20 md:py-28", _className)}>
      <Container>
        <div className="flex flex-wrap items-end gap-12 lg:flex-nowrap lg:gap-20">
          <div className="flex flex-grow flex-col items-start gap-10 lg:flex-[1_1_75%]">
            <div className="flex flex-col gap-4">
              <span className="font-bold">Sort</span>
              <div className="flex gap-4">
                <Toggle
                  id="alphabetical"
                  name="alphabetical"
                  label="A-Z"
                  checked={activeSort === "alphabetical"}
                  onClick={e => {
                    setActiveSort("alphabetical");
                  }}
                />
                {collection === "openStudiosArtists" && (
                  <Toggle
                    id="number"
                    name="artist_no"
                    label="Artist No."
                    checked={activeSort === "artist_no"}
                    onClick={e => {
                      setActiveSort("artist_no");
                    }}
                  />
                )}
              </div>
            </div>
            {/* filters */}
            <div className="flex flex-col gap-4">
              <span className="font-bold">Filter</span>
              <div className="flex flex-wrap gap-4">
                {filters
                  ?.sort((a, b) => a.title.localeCompare(b.title))
                  ?.map(filter => {
                    const { uid, title } = filter;
                    return (
                      <Toggle
                        key={uid}
                        id={uid}
                        name={title}
                        label={title}
                        checked={activeFilters?.includes(uid)}
                        onChange={updateFilters}
                        className="after:bg-green peer-checked:bg-green peer-checked:after:bg-black"
                      />
                    );
                  })}
                {collection === "openStudiosArtists" && (
                  <span className="w-full" />
                )}
                {collection === "openStudiosArtists" &&
                  mediums
                    ?.sort((a, b) => a.title.localeCompare(b.title))
                    ?.map(medium => {
                      const { uid, title } = medium;
                      return (
                        <Toggle
                          key={uid}
                          id={uid}
                          name={title}
                          label={title}
                          checked={activeFilters?.includes(uid)}
                          onChange={updateFilters}
                          className="after:bg-green peer-checked:bg-green peer-checked:after:bg-black"
                        />
                      );
                    })}
              </div>
            </div>
          </div>
          <Search
            content={filteredItems}
            placeholder={t("Search by Name", lang)}
            className="flex-auto"
            queryState={[query, setQuery]}
            resultState={[results, setResults]}
            fields={["name"]}
          />
        </div>
        {/* filtered items */}
        <ArtistGrid
          content={results}
          className="mt-16 flex flex-col space-y-12 lg:mt-12"
        />
      </Container>
    </section>
  );
});

export default Directory;
