import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query ArtistsQuery {
    craft {
      entries(section: "people", type: ["teachingArtists"]) {
        ... on Craft_people_teachingArtists_Entry {
          slug
          name: title
          id
          uid
          type: typeHandle
          url
          link: uri
          image: image0 {
            ...ImageFragment
          }
          tags: teachingArtistsTags {
            ... on Craft_teachingArtists_Category {
              id
              uid
              title
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  const artists = entries.map(entry => {
    return {
      ...entry,
      image: resolveImage(entry.image),
    };
  });

  return artists.reduce((a, b) => {
    // get group name
    const { type } = b;
    // spread existing group (if there is one)
    const group = [...(a[type] || []), b];
    // add the updated group to the object
    return { ...a, [type]: group };
  }, {});
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
